<template>
  <div class='teacher'>
    <list-template
        :current-page='page'
        :loading="loading"
        :search-config='searchConfig'
        :table-config='tableConfig'
        :table-data='tableData'
        :total='total'
        has-add
        @onAdd='add'
        @onChangePage='changeCurrentPage'
        @onReset='search = null'
        @onSearch='onSearch'
        @onHandle="handleClick"
    >
    </list-template>

    <el-dialog :before-close="()=>dialogVisible=false" :close-on-click-modal="false" :visible.sync="dialogVisible" title="提示" width="30%">
      <span style="font-size: 14rem;font-weight: 400;color: #606266;">{{ type === 1 ? '启用后，教师将继续使用此词条评价学生' : type === 2 ? '禁用后，教师将无法使用此词条评价学生' : '请输入10个字内的词条，添加后所有教师均可使用此词条来形容学生表现' }}</span>
      <div v-if="type===3">
        <el-input v-model="name" maxlength="10" placeholder="请输入" show-word-limit style="margin-top: 24rem;width: 180rem"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="determine">{{ type === 1 ? '继续启用' : type === 2 ? '继续禁用' : '提交' }}</el-button>
        <el-button size="mini" @click="cancel">{{ type === 1 ? '点错了' : type === 2 ? '点错了' : '取消' }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import ListTemplate from "@/components/pages/List.vue"

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: {ListTemplate},
  data() {
    return {
      loading: false,
      dialogVisible: false,
      value: 1,
      srcList: [],
      name: '',
      // 表格搜索配置
      searchConfig: [
        {
          prop: 'keyword',
          placeholder: '搜索词条'
        },
      ],
      // 表格配置
      tableConfig: [
        {
          prop: 'name',
          label: '词条'
        },
        {
          label: '状态',
          render: (row) => {
            return row.status === 1 ? '已启用' : '已禁用'
          }
        },
        {
          prop: 'creator',
          label: '创建人'
        },
        {
          prop: 'created_at',
          label: '创建时间',
          width: 300
        },
        {
          label: '操作',
          width: '100',
          handle: true,
        },
      ],
      search: null,
      total: 0,
      // 表格中的数据
      tableData: [],
      type: 3,
      backData: {}
    }
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
  },
  activated() {
    this.getData()
  },
  computed: {
    ...mapState(['page'])
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    /**
     * 获取数据
     */
    getData() {
      let {search, page} = this
      this.loading = true;
      this.$_axios2.get('api/entry/entry-list', {params: {...search, page}}).then(res => {
        this.tableData = res.data.data.list
        this.total = res.data.data.page ? res.data.data.page.total : 0
      }).finally(() => this.loading = false);
    },
    // 搜索按钮被点击
    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val
      this.getData()
    },
    add() {
      this.type = 3;
      this.dialogVisible = true
    },
    handleClick(row, handle) {
      const { type } = handle
      if(type === 'enable') {
        this.type = 1
      } else if(type === 'disable') {
        this.type = 2
      }
      // this.type = row.status ? 1 : 2;
      this.dialogVisible = true;
      this.backData = row
    },
    determine() {
      if (this.type === 3) {
        this.$_axios2.post('api/entry/add-entry', {name: this.name}, {logic: 1}).then(res => {
          // if (res.data.status !== 0) return
          this.$message.success('添加成功')
          this.name = ''
          this.dialogVisible = false
          this.getData()
        })
      } else {
        this.$_axios2.post('api/entry/update-entry-status', {entry_id: this.backData.id, status: this.backData.status === 1 ? 0 : 1}).then(res => {
          this.dialogVisible = false
          this.$message.success('操作成功')
          this.getData()
        })
      }
    },
    cancel() {
      this.name = ''
      this.dialogVisible = false
    },
  },
}
</script>

<style lang='scss' scoped>
.list {
  padding: 24rem 0 0 32rem;

  .search {
    .el-input,
    .el-select {
      width: 240rem;
      margin-right: 24rem;
    }
  }

  .pagination {
    margin-top: 50rem;
  }


}

.title {
  font-size: 20rem;
  font-weight: bold;
  margin-bottom: 20rem;
  color: #333333;
}

.title_item {
  text-align: right;
}
</style>
